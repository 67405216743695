
.title-wrapper {

  h1 {
    margin-bottom: $global-margin / 2;
  }

  p {
    font-size: 14px;
    margin-top: 0;
    opacity: 0.5;
  }
}
