
.item {
  border-bottom: 1px dashed color(theme-white);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.5rem 1rem;

  .title,
  p {
    margin: 0;
  }

  .title {
    font-size: 1rem;
    font-weight: 300;
  }

  .count-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  button {
    background-color: transparent;
    border: 0;
    color: color(theme-white);
    transition: color 400ms;

    &:hover,
    &:focus {
      color: color(theme-blue);
    }

    &.delete {

      &:hover {
        color: color(theme-alert);
      }
    }
  }

  .actions {
    margin-top: 0.5rem;
    margin-left: -0.25rem;
    width: 100%;
  }
}
