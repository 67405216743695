
$theme-colors: (
  'theme-black': #252525,
  'theme-white': #fff,
  'theme-gray': #202124,
  'theme-success': #86c13d,
  'theme-warning': #f1ba2e,
  'theme-alert': #e54040,
  'theme-blue': #00dbff,
  'theme-blue-dark': #1f8392,
);

$global-margin: 1rem;
$global-padding: 1rem;
$global-speed: 400ms;
$colors: $theme-colors;
$body-font: 'Roboto', sans-serif;