
body {
  background-color: color(theme-gray);
  color: color(theme-white);
  padding: 0;
  margin: 0;
  font-family: $body-font;
}

.container {
  margin: ($global-margin * 4) auto;
  max-width: 500px;
  width: 90vw;
}

button {
  cursor: pointer;
}
